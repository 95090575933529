/* -----------------------------------------------

Table of Content

  1. Generator Page
     - 1.1 Header Phone
     - 1.2 Generator Items
      - 1.2.1 Binance
      - 1.2.2 ByBit
      - 1.2.3 OKX
      - 1.2.4 HTX
      - 1.2.5 Trust Wallet
      - 1.2.6 Exodus
      - 1.2.7 Sberbank
      - 1.2.8 Alfa-Bank
     - 1.3 Other Components

----------------------------------- */

/* -----------------------------------
		      1. Generator Page
----------------------------------- */
.gen-page {
  position: relative;
  padding: 50px 0;

  .spin-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 210px);
  }

  .custom-row {
    position: relative;

    .result {
      position: absolute;
      top: -135px;
      left: -4.2%;
      width: 1290px;
      max-width: 1290px;
      overflow: hidden;
      border-radius: 5px;
      outline: 4px dashed $background-grey-200 !important;
      outline-offset: 15px;
      transform: scale(0.3) translate(-100%, -100%);
      z-index: 2;

      .result-spin {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(12);
        z-index: 999;
      }
    }
  }

  .box {
    position: relative;
    width: calc(100% - 410px);
    text-align: left;
    border-radius: 5px;
    margin-left: auto;
    background-color: $element-color;

    form {
      padding: 20px 10px;
    }

    .subtitle {
      position: relative;
      display: flex;
      align-items: center;
      gap: 7px;
      margin-bottom: 30px;
    }

    .system_title {
      display: flex;
      flex-direction: column;
      padding: 0 0.5rem;
      margin-bottom: 30px;

      p {
        font-size: 14px;
      }

      span {
        s {
          position: relative;
          padding: 10px;
          color: $color-main;
          border-radius: 6px;
          outline: 1px dashed rgba(0, 194, 153, 0.2) !important;
          outline-offset: 3px;
          background: rgba(0, 194, 153, 0.1);

          svg {
            position: relative;
            top: 4px;
            margin-left: 5px;
          }
        }
      }
    }
  }

  /* 1.1 Header Phone */
  .header-phone {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 6%;

    &.ios {
      .time-screen {
        position: absolute;
        display: flex;
        gap: 13px;
        top: calc(32% - 1px);
        line-height: 1.3;
        left: calc(12% + 17px);
        font: {
          size: 54px;
          family: "SF UI Text";
        }
        margin-bottom: 0;
        transition: all 0.3s;

        &.add {
          left: calc(10% + 15px);
        }

        .additional {
          position: relative;

          &.geo {
            top: -5px;
            left: -5px;

            img {
              width: 45px;
            }
          }

          &.bother {
            top: -4px;

            img {
              width: 42px;
            }
          }
        }
      }

      .mobile-network {
        position: absolute;
        display: flex;
        align-items: flex-end;
        top: 39%;
        right: calc(25% - 13px);
        gap: 7px;

        span {
          display: block;
          width: 11px;
          height: 41px;
          border-radius: 3px;

          &:nth-child(1) {
            height: 16px;
          }

          &:nth-child(2) {
            height: 24px;
          }

          &:nth-child(3) {
            height: 33px;
          }
        }

        &.e-sim-true {
          top: 38%;
          right: calc(25% - 13px);

          span {
            height: 26px;

            &:nth-child(1) {
              height: 12px;
            }

            &:nth-child(2) {
              height: 16px;
            }

            &:nth-child(3) {
              height: 21px;
            }

            &:nth-child(4) {
              height: 26px;
            }

            &:nth-child(5) {
              position: absolute;
              height: 12px;
              top: 32px;
            }

            &:nth-child(6) {
              position: absolute;
              height: 12px;
              top: 32px;
              left: 18px;
            }

            &:nth-child(7) {
              position: absolute;
              height: 12px;
              top: 32px;
              left: calc(18px * 2);
            }

            &:nth-child(8) {
              position: absolute;
              height: 12px;
              top: 32px;
              left: calc(18px * 3);
            }
          }
        }
      }

      .connection {
        position: absolute;
        line-height: 1.3;
        text-align: center;
        width: 70px;
        top: 37%;
        right: 17.4%;
        font: {
          size: 43px;
          family: "SF UI Text";
        }
        margin-bottom: 0;
        letter-spacing: -2px;

        s {
          transform: translateX(-50%);

          svg {
            position: relative;
            top: -10px;
            left: 2px;
          }
        }

        &.none {
          display: none;
        }
      }

      .airplane {
        position: absolute;
        top: 65px;
        right: 315px;
        width: 50px;
        height: auto;
        transition: all 0.3s;
      }

      .battery {
        position: absolute;
        width: 85px;
        height: 45px;
        top: 38%;
        left: 84%;
        border-radius: 15px;
        overflow: hidden;
        transition: all 0.3s;

        span {
          position: relative;
          display: block;
          height: 100%;

          &.red {
            background-color: #ff3b30 !important;
          }

          &.green {
            background-color: #65c466 !important;
          }
        }

        i {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 1px;
          top: 50%;
          left: 50%;
          line-height: 44px;
          font: {
            size: 41px;
            style: normal;
            family: "SF UI Text";
          }
          transform: translate(-50%, -50%);

          img {
            position: relative;
            width: 22px;
            height: 33px;
          }
        }
      }

      &::after {
        content: "";
        position: absolute;
        width: 6px;
        height: 13px;
        top: 80px;
        right: 112px;
        border-radius: 0 80px 80px 0;
        transition: all 0.3s;
      }

      &.none-connect {
        .connection {
          display: none;
        }

        .mobile-network {
          right: calc(23% - 22px);
        }

        .battery {
          left: 80.5%;
        }

        .airplane {
          right: 275px;
        }

        &::after {
          right: 158px;
        }
      }

      &.dark {
        .time-screen {
          font-weight: 500;
          color: $color-white;
        }

        .mobile-network {
          span {
            background-color: rgba(255, 255, 255, 0.2);

            &:nth-child(5),
            &:nth-child(6),
            &:nth-child(7),
            &:nth-child(8) {
              background-color: $color-white;
            }
          }

          &.net-1 {
            span {
              &:nth-child(1) {
                background-color: $color-white;
              }
            }
          }

          &.net-2 {
            span {
              &:nth-child(1),
              &:nth-child(2) {
                background-color: $color-white;
              }
            }
          }

          &.net-3 {
            span {
              &:nth-child(1),
              &:nth-child(2),
              &:nth-child(3) {
                background-color: $color-white;
              }
            }
          }

          &.net-4 {
            span {
              &:nth-child(1),
              &:nth-child(2),
              &:nth-child(3),
              &:nth-child(4) {
                background-color: $color-white;
              }
            }
          }
        }

        .connection {
          font-weight: 500;
          color: $color-white;

          s {
            svg {
              g path {
                fill: #fff;
              }
            }
          }
        }

        .battery {
          background-color: rgba(255, 255, 255, 0.3);

          span {
            background-color: $color-white;
          }

          i {
            font-weight: 700;
            color: $color-black;
          }
        }

        .airplane {
          filter: invert(1);
        }

        .additional {
          filter: invert(1);
        }

        &::after {
          background-color: rgba(255, 255, 255, 0.3);
        }
      }

      &.light {
        .time-screen {
          font-weight: 550;
          color: $color-black;
        }

        .mobile-network {
          span {
            background-color: rgba(0, 0, 0, 0.2);

            &:nth-child(5),
            &:nth-child(6),
            &:nth-child(7),
            &:nth-child(8) {
              background-color: $color-black;
            }
          }

          &.net-1 {
            span {
              &:nth-child(1) {
                background-color: $color-black;
              }
            }
          }

          &.net-2 {
            span {
              &:nth-child(1),
              &:nth-child(2) {
                background-color: $color-black;
              }
            }
          }

          &.net-3 {
            span {
              &:nth-child(1),
              &:nth-child(2),
              &:nth-child(3) {
                background-color: $color-black;
              }
            }
          }

          &.net-4 {
            span {
              &:nth-child(1),
              &:nth-child(2),
              &:nth-child(3),
              &:nth-child(4) {
                background-color: $color-black;
              }
            }
          }
        }

        .connection {
          font-weight: 600;
          color: $color-black;

          span {
            svg {
              filter: invert(1);
            }
          }
        }

        .battery {
          background-color: rgba(0, 0, 0, 0.3);

          span {
            background-color: $color-black;
          }

          i {
            font-weight: 600;
            color: $color-white;
          }
        }

        &::after {
          background-color: rgba(0, 0, 0, 0.3);
        }
      }

      &.full {
        &.dark {
          &::after {
            background-color: #fff;
          }
        }

        &.light {
          &::after {
            background-color: #000;
          }
        }
      }
    }

    &.android {
      .time-screen {
        position: absolute;
        top: 32%;
        left: 7%;
        font: {
          size: 15px;
          family: "SF UI Text";
        }
        margin-bottom: 0;
      }

      .battery {
        position: absolute;
        width: 18px;
        height: 7px;
        border-radius: 1px;

        span {
          position: relative;
          display: block;
          height: 100%;
          border-radius: 2px;

          &.red {
            background-color: #f2052e !important;
          }

          &.green {
            background-color: #65c466 !important;
          }
        }

        i {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          top: 55%;
          left: 25px;
          line-height: 12px;
          font: {
            size: 12px;
            style: normal;
            family: "SF UI Text";
          }
          transform: translateY(-50%);
        }

        img {
          position: absolute;
          top: -0.5px;
          left: 50%;
          width: 5px;
          height: 8.5px;
          transform: translateX(-50%);
        }
      }

      &.dark {
        .time-screen {
          color: #fefefe;
        }

        .battery {
          top: 42%;
          left: 85.5%;
          outline: 2px solid #fefefe !important;
          outline-offset: 2px;

          span {
            background: #fefefe;
          }

          i {
            color: #fefefe;
          }
        }
      }

      &.light {
        .time-screen {
          color: #4a4a4a;
        }
        .battery {
          top: 41%;
          left: 85%;
          outline: 2px solid #4a4a4a !important;
          outline-offset: 1.5px;

          span {
            background: #4a4a4a;
          }

          i {
            color: #4a4a4a;
          }

          img {
            filter: invert(1);
          }
        }
      }
    }
  }

  /* 1.2 Generator Items  */
  .result-img {
    position: relative;
    border-radius: 5px;

    .img-thumbnail {
      padding: 0;
      border: none !important;
    }

    p {
      margin-bottom: 0;
    }

    /* 1.2.1 Binance */
    &.binance {
      &.mail {
        &.deposit,
        &.withdrawal {
          .time-arrival {
            position: absolute;
            width: 87%;
            top: calc(11% - 3px);
            left: calc(4% - 4px);
            color: #000000;
            font: {
              size: 56px;
              weight: 400;
              family: "SF UI Text";
            }
            text-align: left;
            letter-spacing: -1.5px;
            line-height: 85px;
            margin-bottom: 0;

            span {
              position: relative;
              color: $color-black;
              font: {
                size: 26px;
                weight: 500;
              }
              padding: 10px 14px;
              margin-left: 15px;
              border-radius: 14px;
              letter-spacing: 1px;
              border: none;
              background: #eee;
            }
          }

          .time-mail {
            position: absolute;
            top: calc(21% - 15px);
            left: calc(29% - 8px);
            color: #696969;
            font: {
              size: 28px;
              weight: 400;
              family: "SF UI Text";
            }
            margin-bottom: 0;
          }

          .status {
            position: absolute;
            top: calc(34% + 5px);
            left: 7.2%;
            text-align: left;
            color: #000000;
            font: {
              size: 47px;
              weight: 700;
              family: "SF UI Text";
            }
            letter-spacing: -0.7px;
            margin-bottom: 0;
          }

          .text {
            position: absolute;
            width: 85%;
            top: calc(38% + 8px);
            left: 7.2%;
            text-align: left;
            color: #000000;
            font: {
              size: 33px;
              weight: 400;
              family: "SF UI Text";
            }
            line-height: 59px;
            letter-spacing: -0.6px;
            margin-bottom: 0;

            span {
              color: #f1c026;
              font-weight: 400;
            }

            &.v2 {
              top: calc(53% + 23px);

              span {
                text-decoration: underline !important;
              }
            }

            &.v3 {
              top: calc(60% + 10px);
              left: 7.8%;
              font-style: italic;
              line-height: 60px;
              word-spacing: 4px;
            }
          }
        }

        &.withdrawal {
          .time-mail {
            top: calc(21% - 15px);
          }

          .status {
            top: calc(34% + 5px);
          }

          .text {
            top: calc(39% - 21px);
            line-height: 1.81;

            s {
              display: block;
              height: 60px;
              width: 94%;
              word-spacing: 4px;
              word-break: break-all;

              u {
                letter-spacing: 1px;
              }
            }

            &.v2 {
              top: calc(58% + 3px);

              span {
                text-decoration: underline !important;
              }
            }

            &.v3 {
              top: calc(64% + 20px);

              font-style: italic;
            }
          }
        }

        &.dark {
          .time-arrival {
            font-weight: 400;
            color: #eaecef;

            span {
              font-weight: 500;
              color: #9b9fa5;
              padding: 7px 11px;
              background: transparent;
              border: 3px solid #5f6368;
            }
          }

          .time-mail {
            color: #9b9fa5;
          }

          .status {
            color: #eaecef;
          }

          .text {
            color: #fefefe;

            span {
              color: #76580d;
              font-weight: 500;
            }
          }
        }
      }

      &.wallet {
        &.deposit,
        &.withdrawal {
          .amount {
            position: absolute;
            width: 100%;
            text-align: center;
            line-height: 1.3;
            top: calc(12% + 19px);
            left: 50%;
            color: #202630;
            font: {
              size: 85px;
              family: "PF Din Text Universal";
            }
            letter-spacing: 0;
            transform: translateX(-50%);
          }

          .network {
            position: absolute;
            top: calc(31% + 9px);
            line-height: 1.3;
            right: 66px;
            color: #202630;
            font: {
              size: 42px;
              weight: 400;
              family: "PF Din Text Universal";
            }
            letter-spacing: 1px;
          }

          .content {
            position: absolute;
            width: 54%;
            top: 35%;
            right: calc(8% + 3px);
            color: #202630;
            font: {
              size: 44px;
              weight: 400;
              family: "PF Din Text Universal";
            }
            text-align: right;
            word-break: break-all;
            line-height: 54px;
            letter-spacing: 1px;

            &.txid {
              top: calc(41% - 12px);
              text-decoration: underline !important;
            }

            &.spot {
              top: calc(48% + 11px);
              right: calc(3% + 7px);
            }

            &.date {
              width: auto;
              top: calc(53% - 15px);
              right: calc(3% + 5px);
              font-size: 43px;
            }
          }

          .course {
            position: absolute;
            display: flex;
            align-items: center;
            gap: 197px;
            top: calc(67% + 13px);
            left: calc(6% - 2px);
            color: #202630;
            font: {
              size: 48px;
              weight: 500;
              family: "PF Din Text Universal";
            }

            .course__item {
              display: flex;
              flex-direction: column;
              align-items: flex-start;

              span {
                font: {
                  size: 36px;
                  weight: 400;
                }
                padding-top: 7px;
                letter-spacing: 1px;

                &.red {
                  color: #e23939;
                }

                &.green {
                  color: #2ebd85;
                }
              }

              &:last-child {
                padding-left: 26px;
              }
            }
          }

          &.dark {
            .amount,
            .content,
            .course,
            .network {
              color: #eaecef;
            }
          }
        }

        &.withdrawal {
          .amount {
            top: calc(13% - 8px);
          }

          .network {
            top: calc(32% + 17px);
          }

          .content {
            top: calc(37% - 19px);

            &.txid {
              top: calc(41% + 24px);
            }

            &.spot {
              top: calc(57% + 24px);
            }

            &.date {
              top: calc(62% - 3px);
            }

            &.entry-amount {
              top: calc(49% + 20px);
              right: calc(3% + 7px);
            }

            &.commission {
              top: calc(53% + 22px);
              right: calc(3% + 7px);
            }
          }
        }

        &.main {
          .main-balance {
            position: absolute;
            top: calc(24% - 11px);
            left: 47px;
            display: flex;
            line-height: 1.3;
            flex-direction: column;
            color: #202630;
            font: {
              size: 99px;
              weight: 500;
              family: "PF Din Text Universal";
            }
            text-align: start;
            align-items: flex-start;

            .currency {
              position: relative;
              top: 0;
              font: {
                size: 44px;
                weight: 400;
              }
              padding-left: 18px;

              &::after {
                content: "";
                position: absolute;
                top: 30px;
                right: -36px;
                width: 0px;
                height: 0px;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-top: 10px solid currentColor;
              }

              &::before {
                content: "";
                position: absolute;
                top: 24px;
                right: -36px;
                width: 20px;
                height: 6px;
                background-color: currentColor;
              }
            }
          }

          .pnl {
            position: absolute;
            top: calc(28% + 7px);
            left: 47px;
            color: #202630;
            font: {
              size: 42px;
              weight: 400;
              family: "PF Din Text Universal";
            }
            text-align: start;
            letter-spacing: 1.6px;

            span {
              position: relative;
              top: 2px;

              svg {
                position: relative;
                top: 12px;
                margin-left: 10px;
              }

              &.green {
                color: #2fbd85;
              }

              &.red {
                color: #f6465d;
              }
            }
          }

          .coin-list {
            position: absolute;
            width: 100%;
            height: 1160px;
            top: 1389px;
            overflow: hidden;
            padding-top: 5px;
            padding-left: 44px;
            padding-right: 46px;
            font-family: "PF Din Text Universal";
            text-align: start;

            .item {
              line-height: 1;
              display: flex;
              justify-content: space-between;
              padding-bottom: 39px;
              margin-bottom: 47px;
              border-bottom: 1px solid #eaecef;

              .info {
                display: flex;
                align-items: flex-start;
                gap: 25px;

                .icon {
                  position: relative;
                  top: -5px;
                  width: 61px;
                  height: 61px;
                  font-size: 0;

                  .icon-img {
                    width: 100%;
                    height: auto;
                  }
                }

                .info-text {
                  display: flex;
                  flex-direction: column;
                  gap: 20px;

                  .item__title {
                    color: #202630;
                    font: {
                      size: 49px;
                      weight: 500;
                    }
                  }

                  .item__name,
                  .item__amount,
                  .item__pnl {
                    color: #81858c;
                    font: {
                      size: 35px;
                      weight: 400;
                    }
                    letter-spacing: 2.3px;
                  }

                  .item__amount {
                    padding-top: 16px;
                  }

                  .item__pnl {
                    padding-top: 4px;
                  }
                }
              }

              .balance {
                display: flex;
                flex-direction: column;
                text-align: end;

                .value {
                  color: #202630;
                  font: {
                    size: 50px;
                    weight: 500;
                  }
                }

                .usdt,
                .cost,
                .pnl-item {
                  color: #81858c;
                  font: {
                    size: 37px;
                    weight: 400;
                  }
                  padding-top: 19px;
                }

                .cost {
                  color: #202630;
                  padding-top: 34px;
                }

                .pnl-item {
                  color: #202630;
                  padding-top: 23px;

                  &.green {
                    color: #2fbd85 !important;
                  }

                  &.red {
                    color: #f6465d !important;
                  }
                }
              }
            }
          }

          &.dark {
            .main-balance {
              color: #eaecef;
            }

            .pnl {
              color: #eaecef;
            }

            .coin-list {
              .item {
                border-bottom: 3px solid #2b323d;

                .info {
                  .info-text {
                    .item__title {
                      color: #eaecef;
                    }
                  }
                }

                .balance {
                  .value {
                    color: #eaecef;
                  }

                  .cost {
                    color: #eaecef;
                  }

                  .pnl-item {
                    color: #eaecef;
                  }
                }
              }
            }
          }
        }
      }
    }

    /* 1.2.2 ByBit */
    &.bybit {
      &.mail {
        &.deposit {
          color: #121212;

          .details {
            position: absolute;
            width: 100%;
            top: 323px;
            left: 0;
            font: {
              weight: 400;
              family: "Inter";
            }

            .time {
              position: absolute;
              top: 165px;
              left: 322px;
              color: #5e6368;
              font: {
                size: 27px;
                family: "Inter";
              }
            }

            .amount {
              position: absolute;
              top: 1038px;
              left: 525px;
              font-size: 38px;
              color: #313131;
            }

            .network {
              position: absolute;
              top: 1164px;
              left: 377px;
              font-size: 38px;
              color: #313131;
            }

            .address {
              position: absolute;
              top: 1386px;
              left: 168px;
              font-size: 38px;
              letter-spacing: -1px;
              color: #313131;
            }

            .date {
              position: absolute;
              top: 1488px;
              left: 168px;
              width: 760px;
              font-size: 38px;
              text-align: left;
              letter-spacing: -1px;
              line-height: 97px;
              color: #313131;

              &::before {
                content: " ";
                padding-left: 470px;
              }
            }
          }

          &.dark {
            color: #fefefe;

            .details {
              .time {
                color: #9aa0a6;
              }

              .amount,
              .network,
              .address,
              .date  {
                color: #d4d4d5;
              }
            }
          }
        }

        &.withdrawal {
          color: #121212;

          .details {
            position: absolute;
            top: 1314px;
            left: 169px;
            width: 100%;
            text-align: left;
            font: {
              size: 38px;
              weight: 400;
              family: "Inter";
            }
            line-height: 1;

            .time {
              position: absolute;
              top: -737px;
              left: 153px;
              color: #5e6368;
              font: {
                size: 27px;
                family: "Inter";
              }
            }

            .amount {
              position: absolute;
              width: calc(100% - 2* 150px);
              left: 0;
              top: 0;
              font-weight: 400;
              letter-spacing: 0.8px;
              line-height: 96px;
            }

            .network {
              position: absolute;
              top: 260px;
              left: 196px;
            }

            .address {
              position: absolute;
              top: 366px;
              left: 0;
              word-break: break-all;
              width: 955px;
              line-height: 96px;

              &::before {
                content: " ";
                padding-left: 295px;
              }
            }

            .hash {
              position: absolute;
              top: 597px;
              left: 0px;
              word-wrap: break-word;
              width: calc(100% - 2* 250px);
              line-height: 96px;
            }
          }

          &.dark {
            color: #fefefe;

            .amount,
              .network,
              .address,
              .hash  {
                color: #d4d4d5;
              }
          }
        }
      }

      &.wallet {
        &.main {
          .main-asset {
            position: absolute;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            top: 450px;
            left: 96px;
            text-align: start;
            color: #121214;
            font: {
              size: 79px;
              weight: 600;
              family: "IBM Plex Sans";
            }
            line-height: 1.3;

            .currency {
              position: relative;
              top: 2px;
              padding-left: 30px;
              font: {
                size: 35px;
                weight: 400;
              }

              &::after {
                content: "";
                position: absolute;
                top: 15px;
                right: -29px;
                width: 0px;
                height: 0px;
                border-radius: 5px;
                border-left: 11px solid transparent;
                border-right: 11px solid transparent;
                border-top: 15px solid currentColor;
              }
            }

            .btc {
              position: relative;
              color: #81858c;
              font: {
                size: 35px;
                weight: 400;
              }
              padding-top: 12px;

              .icon {
                position: absolute;
                top: 17px;
                right: -47px;
              }
            }
          }

          .available-balance {
            position: absolute;
            text-align: start;
            top: 709px;
            left: 95px;
            color: #121214;
            font: {
              size: 35px;
              family: "IBM Plex Sans";
            }

            .usd {
              font-weight: 300;
            }

            .btc {
              padding-left: 21px;
              font-weight: 400;
              color: #81858c;
            }
          }

          .coin-list {
            position: absolute;
            width: 100%;
            top: 1642px;
            height: 1137px;
            font: {
              family: "IBM Plex Sans";
            }
            padding-left: 48px;
            padding-right: 46px;
            text-align: start;
            overflow: hidden;

            .item {
              line-height: 1;
              display: flex;
              justify-content: space-between;

              &:not(:first-of-type) {
                margin-top: 107px;
              }

              .info {
                display: flex;
                align-items: center;
                gap: 47px;

                .icon {
                  width: 72px;
                  height: 72px;
                  font-size: 0;

                  .icon-img {
                    width: 100%;
                    height: auto;
                  }
                }

                .info-text {
                  display: flex;
                  flex-direction: column;
                  gap: 21px;
                }

                .item__title {
                  color: #121214;
                  font: {
                    size: 40px;
                    weight: 600;
                  }
                }

                .item__name {
                  color: #81858c;
                  font: {
                    size: 28px;
                    weight: 400;
                  }
                  letter-spacing: 1px;
                }
              }

              .balance {
                display: flex;
                flex-direction: column;
                text-align: end;

                .value {
                  color: #121214;
                  font-size: 40px;
                }

                .usd {
                  color: #81858c;
                  font: {
                    size: 30px;
                    weight: 400;
                  }
                  padding-top: 20px;
                }
              }
            }
          }

          &.dark {
            .main-asset {
              font-weight: 500;
              color: #ffffff;

              .btc {
                color: #adb1b9;
              }
            }

            .available-balance {
              .value {
                font-weight: 500;
                color: #ffffff;
              }

              .btc {
                color: #adb1b9;
              }
            }

            .coin-list {
              .item {
                .info {
                  .item__title {
                    color: #ffffff;
                  }
                }

                .balance {
                  .value {
                    color: #ffffff;
                  }

                  .usd {
                    color: #adb1b9;
                  }
                }
              }
            }
          }
        }

        &.assets {
          font-family: "IBM Plex Sans";

          .general-assets {
            position: absolute;
            top: 485px;
            left: 48px;
            line-height: 1;

            .value {
              display: flex;

              .value-numb {
                color: #121214;
                position: relative;
                font: {
                  size: 79px;
                  weight: 600;
                }
              }

              .usd-currency {
                position: absolute;
                bottom: 6px;
                right: -97px;
                font: {
                  size: 34px;
                  weight: 400;
                }

                &::after {
                  content: "";
                  position: absolute;
                  top: 9px;
                  right: -31px;
                  width: 0px;
                  height: 0px;
                  border-radius: 5px;
                  border-left: 11px solid transparent;
                  border-right: 11px solid transparent;
                  border-top: 15px solid currentColor;
                }
              }
            }

            .btc {
              display: flex;
              margin-top: 29px;
              font: {
                size: 34px;
                weight: 400;
              }
              color: #80858d;

              .icon {
                position: relative;
                left: 19px;
                top: 1px;
              }
            }
          }

          .my-accounts {
            position: absolute;
            top: 1254px;
            left: 96px;
            text-align: left;
            line-height: 1;

            .value {
              font-size: 45px;
              color: #121214;

              &:not(:first-of-type) {
                margin-top: 177px;
              }
            }

            .currency {
              position: relative;
              top: 2px;
              font: {
                size: 34px;
                weight: 400;
              }
              padding-left: 10px;
            }
          }

          &.dark {
            .general-assets {
              .value {
                .value-numb {
                  color: #ffffff;
                }
              }

              .btc {
                color: #acb1b9;
              }
            }

            .my-accounts {
              .value {
                color: #ffffff;
              }
            }
          }
        }

        &.deposit {
          position: relative;
          color: #121214;
          font-family: "IBM Plex Sans";

          .amount {
            position: absolute;
            top: 494px;
            left: 0;
            width: 100%;
            font: {
              size: 49px;
              weight: 600;
            }
            line-height: 1;
            text-align: center;
          }

          .details {
            position: absolute;
            top: 783px;
            width: 50%;
            right: 49px;
            font: {
              size: 34px;
              weight: 500;
            }
            text-align: right;
            line-height: 1;
          }

          .network {
            position: absolute;
            top: 93px;
            right: 0;
          }

          .time {
            position: absolute;
            top: 186px;
            right: 0;
          }

          .address {
            position: absolute;
            top: 274px;
            right: 49px;
            width: 660px;
            line-height: 45px;
            word-wrap: break-word;
          }

          .hash {
            position: absolute;
            top: 412px;
            right: 49px;
            width: 660px;
            line-height: 45px;
            word-wrap: break-word;
          }

          &.dark {
            color: #ffffff;
          }
        }

        &.withdrawal {
          position: relative;
          font-family: "IBM Plex Sans";
          color: #121214;

          .amount {
            position: absolute;
            top: 494px;
            left: 0;
            width: 100%;
            font-size: 49px;
            font-weight: 600;
            line-height: 1;
            text-align: center;
          }

          .details {
            position: absolute;
            top: 783px;
            width: 50%;
            right: 49px;
            font-size: 34px;
            font-weight: 500;
            text-align: right;
            line-height: 1;
          }

          .commission {
            position: absolute;
            top: 93px;
            right: 0px;
          }

          .network {
            position: absolute;
            top: 186px;
            right: 0;
          }

          .time {
            position: absolute;
            top: 279px;
            right: 0;
          }

          .address {
            position: absolute;
            top: 367px;
            right: 49px;
            width: 660px;
            line-height: 45px;
            word-wrap: break-word;
          }

          .hash {
            position: absolute;
            top: 505px;
            right: 49px;
            width: 660px;
            line-height: 45px;
            word-wrap: break-word;
          }

          &.dark {
            color: #ffffff;
          }
        }
      }
    }

    /* 1.2.3 OKX */
    &.okx {
      &.wallet {
        &.withdrawal {
          color: #000000;

          .amount {
            position: absolute;
            top: 447px;
            left: 0px;
            width: 100%;
            font: {
              size: 76px;
              family: "Outfit";
            }
            letter-spacing: 0;
            font-weight: 600;
            line-height: 1;
            text-align: center;
          }

          .info {
            position: absolute;
            width: 100%;
            top: 992px;
            text-align: right;
            font: {
              size: 42px;
              weight: 400;
              family: "Outfit", "Inter";
            }
            right: 58px;
            line-height: 49px;
            letter-spacing: 0px;

            .blockchain {
              position: absolute;
              right: 0;
            }

            .type {
              position: absolute;
              right: 0;
              top: 111px;
              width: 331px;
            }

            .status {
              position: absolute;
              right: 0;
              top: 271px;
            }

            .domain {
              position: absolute;
              right: 82px;
              top: 382px;
              width: 554px;
              word-break: break-all;
            }

            .transactionId {
              position: absolute;
              right: 82px;
              top: 541px;
              width: 554px;
              word-break: break-all;
            }

            .fee {
              position: absolute;
              right: 0;
              top: 749px;
            }

            .date {
              position: absolute;
              right: 0;
              top: 859px;
            }

            .number {
              position: absolute;
              right: 82px;
              top: 971px;
            }
          }

          &.dark {
            color: #fff;
          }
        }

        &.deposit {
          color: #000000;

          .amount {
            position: absolute;
            top: 447px;
            left: 0px;
            width: 100%;
            font: {
              size: 76px;
              weight: 600;
              family: "Outfit";
            }
            letter-spacing: 0;
            line-height: 1;
            text-align: center;
          }

          .info {
            position: absolute;
            width: 100%;
            top: 722px;
            right: 65px;
            font: {
              size: 42px;
              weight: 400;
              family: "Outfit", "Inter";
            }
            text-align: right;
            line-height: 49px;
            letter-spacing: 0px;

            .blockchain {
              position: absolute;
              right: 0;
            }

            .type {
              position: absolute;
              right: 0;
              top: 111px;
              width: 331px;
            }

            .status {
              position: absolute;
              right: 0;
              top: 221px;
            }

            .domain {
              position: absolute;
              right: 71px;
              top: 331px;
              width: 624px;
              word-break: break-all;
            }

            .transactionId {
              position: absolute;
              right: 71px;
              top: 492px;
              width: 640px;
              word-break: break-all;
            }

            .date {
              position: absolute;
              right: 0;
              top: 700px;
            }

            .number {
              position: absolute;
              right: 82px;
              top: 971px;
            }
          }

          &.dark {
            color: #fff;
          }
        }

        &.balance {
          color: #000000;
          font-family: "Outfit", "Inter";

          .value {
            position: absolute;
            top: 419px;
            left: 50px;
            font-size: 102px;
            line-height: 1;
            letter-spacing: 1px;

            .currency {
              position: relative;
              left: 22px;
              font-size: 47px;

              &::after {
                content: "";
                position: absolute;
                top: 25px;
                right: -31px;
                width: 0px;
                height: 0px;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-top: 14px solid currentColor;
              }
            }
          }

          .coin-list {
            position: absolute;
            top: 1000px;
            width: 100%;
            padding-left: 50px;
            margin-bottom: 0;

            .item {
              position: relative;
              display: flex;
              flex-basis: 100%;
              border-bottom: 1px solid #ebebeb;
              padding-bottom: 52px;
              padding-top: 52px;
            }

            .info {
              display: flex;
              align-items: center;
              gap: 20px;
            }

            .item__title {
              position: relative;
              top: -3px;
              left: -2px;
              font-size: 48px;
            }

            .icon-img {
              width: 68px;
            }

            .coin-balance {
              position: absolute;
              top: 50%;
              left: 539px;
              font-size: 47px;
              line-height: 1;
              text-align: left;
              transform: translateY(-50%);
            }

            .balance-usd {
              position: relative;
              left: 1px;
              top: -4px;
              font: {
                size: 35px;
                weight: 400;
              }
              margin-top: 11px;
              color: #929292;
            }

            .dots {
              position: absolute;
              display: flex;
              top: 50%;
              right: 57px;
              gap: 12px;
              transform: translateY(-50%);
            }

            .dot {
              width: 10px;
              height: 10px;
              border-radius: 50%;
              background-color: #929292;
            }
          }

          &.dark {
            color: #fff;
          }
        }
      }

      &.mail {
        &.deposit {
          color: #000000;
          font-family: "Inter";

          .time-arrival {
            position: absolute;
            top: 322px;
            left: 48px;
            right: 126px;
            font: {
              size: 61px;
              weight: 500;
            }
            line-height: 97px;
            text-align: left;

            span {
              position: relative;
              top: -8px;
              font-size: 36px;
              padding: 10px 14px;
              margin-left: 15px;
              border-radius: 15px;
              letter-spacing: 0;
              background-color: #eeeeee;
            }
          }

          .time {
            position: absolute;
            top: 516px;
            left: 335px;
            color: #5f6368;
            font: {
              size: 36px;
              weight: 400;
            }
            line-height: 1;
          }

          .mail-title {
            position: absolute;
            top: 982px;
            left: 0;
            text-align: left;
            width: 100%;
            color: #1b1b1b;
            font: {
              size: 72px;
              weight: 700;
            }
            padding: 0px 120px;
          }

          .mail-text {
            position: absolute;
            top: 1102px;
            left: 0;
            text-align: left;
            width: 100%;
            line-height: 66px;
            padding: 0px 120px;
            color: #1b1b1b;
            font: {
              size: 42px;
              weight: 500;
            }
            letter-spacing: -0.8px;

            .bold {
              font-weight: 700;
            }
          }

          .mail-uid {
            position: absolute;
            top: 1648px;
            left: 217px;
            color: #252525;
            font: {
              size: 40px;
              weight: 500;
            }
            line-height: 1;
            letter-spacing: -1.1px;
          }

          &.dark {
            .time {
              color: #979da3;
            }

            .time-arrival {
              font-weight: 400;
              color: #eaecef;

              span {
                color: #9b9fa5;
                font-weight: 500;
                padding: 7px 11px;
                background: transparent;
                border: 3px solid #9b9fa5;
              }
            }

            .mail-uid,
            .mail-text {
              color: #fff;
            }

            .mail-title {
              color: #fff;
              font-weight: 600;
            }
          }
        }

        &.withdrawal {
          color: #000000;
          font-family: "Inter";

          .time-arrival {
            position: absolute;
            width: 87%;
            top: calc(12% - 14px);
            left: calc(4% - 4px);
            font: {
              size: 67px;
              family: "SF UI Text";
            }
            text-align: left;
            letter-spacing: -2.5px;
            line-height: 97px;
            margin-bottom: 0;

            span {
              position: relative;
              top: -8px;
              font-size: 36px;
              padding: 10px 14px;
              margin-left: 15px;
              border-radius: 15px;
              letter-spacing: 0;
              background-color: #eeeeee;
            }
          }

          .time {
            position: absolute;
            top: 612px;
            left: 335px;
            color: #5f6368;
            font: {
              size: 36px;
              weight: 400;
            }
            line-height: 1;
          }

          .mail-title {
            position: absolute;
            top: 1062px;
            left: 0;
            text-align: left;
            width: 100%;
            line-height: 108px;
            color: #1b1b1b;
            font: {
              size: 72px;
              weight: 700;
            }
            padding: 0px 120px;
          }

          .mail-text {
            position: absolute;
            top: 1301px;
            left: 0;
            text-align: left;
            width: 100%;
            line-height: 66px;
            color: #1b1b1b;
            padding: 0px 120px;
            font: {
              size: 42px;
              weight: 500;
            }
            letter-spacing: -0.8px;

            .bold {
              font-weight: 600;
            }
          }

          .address {
            position: absolute;
            top: 1607px;
            left: 565px;
            text-align: left;
            word-break: break-all;
            width: 520px;
            color: #313131;
            font: {
              size: 35px;
              weight: 500;
            }
            line-height: 42px;
            letter-spacing: -0.2px;
          }

          .txId {
            position: absolute;
            top: 1728px;
            left: 565px;
            text-align: left;
            word-break: break-all;
            width: 520px;
            color: #313131;
            font: {
              size: 35px;
              weight: 500;
            }
            line-height: 42px;
            letter-spacing: -1.2px;
          }

          .withdrawalId {
            position: absolute;
            top: 1934px;
            left: 565px;
            text-align: left;
            word-break: break-all;
            width: 520px;
            color: #313131;
            font: {
              size: 35px;
              weight: 500;
            }
            line-height: 42px;
            letter-spacing: -1.2px;
          }

          &.dark {
            .time-arrival {
              font-weight: 400;
              color: #eaecef;

              span {
                color: #9b9fa5;
                font-weight: 500;
                padding: 7px 11px;
                background: transparent;
                border: 3px solid #9b9fa5;
              }
            }

            .mail-title,
            .mail-text {
              color: white;
            }

            .mail-title {
              font-weight: 600;
            }

            .address,
            .txId,
            .withdrawalId {
              color: #cbcbcc;
            }

            .time {
              color: #979da3;
            }

            color: white;
          }
        }
      }
    }

    /* 1.2.4 HTX */
    &.htx {
      &.mail {
        &.deposit,
        &.withdrawal {
          .time {
            position: absolute;
            top: 613px;
            left: 326px;
            color: #5f6368;
            font: {
              size: 36px;
              weight: 400;
              family: "SF UI Text";
            }
            line-height: 1;
          }

          .contents {
            position: absolute;
            width: 86%;
            top: calc(47% - 6px);
            left: calc(7% + 3px);
            color: #000000;
            font: {
              size: 42px;
              weight: 400;
              family: "SF UI Text";
            }
            text-align: left;

            b {
              s {
                text-transform: lowercase;
              }
            }
          }

          &.dark {
            .time {
              color: #9ba0a6;
            }

            .contents {
              color: #e2e2e3;
            }
          }
        }
      }

      &.wallet {
        &.deposit,
        &.withdrawal {
          .amount {
            position: absolute;
            top: calc(11% + 12px);
            left: calc(8% + 8px);
            color: #0173e5;
            font: {
              size: 95px;
              weight: 500;
              family: "Yantramanav";
            }
          }

          .wallet,
          .hash {
            position: absolute;
            top: calc(30% + 15px);
            right: calc(3% + 8px);
            width: 571px;
            color: #0173e5;
            font: {
              size: 46px;
              weight: 400;
              family: "Yantramanav";
            }
            word-break: break-all;
            text-align: right;
            line-height: 48px;
            text-decoration: underline !important;
          }

          .wallet {
            width: 605px;
            top: calc(30% + 15px);
            right: calc(3% + 6px);
            font-size: 47px;
          }

          .date,
          .fee,
          .network {
            position: absolute;
            top: calc(41% + 3px);
            right: calc(3% + 8px);
            color: #000000;
            font: {
              size: 46px;
              weight: 400;
              family: "Yantramanav";
            }
          }

          .network {
            top: calc(39% + 11px);
            right: calc(3% + 6px);
            font-size: 47px;
          }

          .fee {
            top: calc(44% + 11px);
            right: calc(3% + 6px);
            font-size: 47px;
          }

          &.dark {
            .date,
            .fee,
            .network {
              color: #e6e6e6;
            }
          }
        }

        &.withdrawal {
          .hash {
            top: calc(49% + 15px);
          }

          .date {
            top: calc(60% + 4px);
          }
        }

        &.main {
          .balance-asset {
            position: absolute;
            top: calc(13% + 11px);
            left: calc(7% + 7px);
            color: #fff;
            font: {
              size: 88px;
              weight: 400;
              family: "Yantramanav";
            }

            &.v2 {
              top: calc(54% - 2px);
              left: calc(7% + 7px);
              color: #000000;
              font: {
                size: 62px;
                weight: 500;
              }
            }
          }

          .pnl-asset {
            position: absolute;
            top: calc(23% - 9px);
            left: calc(7% + 17px);
            color: #fff;
            font: {
              size: 53px;
              weight: 500;
              family: "Yantramanav";
            }

            span {
              color: #b4d6f7;
            }

            &.v2 {
              top: calc(58% - 21px);
              left: calc(30% + 17px);
              color: #000000;
              font-size: 46px;
              font-weight: 500;
            }
          }

          .coin-list {
            position: absolute;
            top: calc(75% - 5px);
            width: 100%;
            height: 453px;
            color: #000000;
            font-family: "Yantramanav";
            overflow: hidden;
            padding-left: 54px;
            padding-right: 54px;
            margin-bottom: 0;

            .item {
              position: relative;
              display: flex;
              justify-content: space-between;
              flex-basis: 100%;
              padding-top: 52px;
              padding-bottom: 52px;

              .info {
                display: flex;
                align-items: center;
                gap: 27px;

                .icon-img {
                  width: 70px;
                }

                .title {
                  font-size: 54px;
                }
              }

              .balance {
                position: absolute;
                top: 27px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                left: calc(39% - 6px);

                .usdt {
                  font: {
                    size: 54px;
                    weight: 500;
                  }
                }

                .value {
                  position: relative;
                  top: -11px;
                  font: {
                    size: 46px;
                    weight: 400;
                  }
                }
              }

              .pnl {
                position: absolute;
                top: 36px;
                right: 0;
                display: flex;
                align-items: flex-end;
                flex-direction: column;
                gap: 3px;
                line-height: 1;

                .usd {
                  font: {
                    size: 54px;
                    weight: 500;
                  }

                  &.green {
                    color: #009f6f;
                  }

                  &.red {
                    color: #e94359;
                  }
                }

                .rate {
                  font: {
                    size: 46px;
                    weight: 500;
                  }

                  &.green {
                    color: #009f6f;
                  }

                  &.red {
                    color: #e94359;
                  }
                }
              }
            }
          }

          &.dark {
            .balance-asset {
              &.v2 {
                color: #e6e6e6;
              }
            }

            .pnl-asset {
              &.v2 {
                color: #e6e6e6;
              }
            }

            .coin-list {
              .item {
                .info {
                  .title {
                    color: #e6e6e6;
                  }
                }

                .balance {
                  color: #e6e6e6;
                }
              }
            }
          }
        }
      }
    }

    /* 1.2.5 Trust Wallet */
    &.trust {
      &.coin {
        .name {
          position: absolute;
          display: flex;
          flex-direction: column;
          gap: 3px;
          top: calc(6% + 23px);
          left: calc(50% + 1px);
          color: #000000;
          font: {
            size: 54px;
            family: "PF Din Text Universal";
          }
          text-align: center;
          letter-spacing: 3px;
          transform: translateX(-50%);

          span {
            position: relative;
            display: none;
            align-items: center;
            gap: 70px;
            font-size: 35px;
            color: #848e9c;

            b {
              font-weight: 400;
            }

            &::after {
              content: "";
              position: absolute;
              width: 3px;
              height: 32px;
              top: 8px;
              left: 55%;
              opacity: 0.3;
              background-color: #848e9c;
            }
          }

          &.network {
            top: calc(5% + 20px);

            span {
              display: flex;
            }
          }
        }

        .currency-icon {
          position: absolute;
          width: 150px;
          height: 150px;
          top: calc(14% - 9px);
          left: 50%;
          transform: translateX(-50%);

          &.network {
            width: 165px;
            height: 165px;
            left: calc(50% + 8px);
          }
        }

        .currency {
          position: absolute;
          width: 100%;
          top: calc(20% + 1px);
          left: 50%;
          color: #000000;
          font: {
            size: 85px;
            family: "PF Din Text Universal";
          }
          text-align: center;
          letter-spacing: 2px;
          transform: translateX(-50%);
        }

        .total {
          position: absolute;
          width: 100%;
          top: calc(24% + 2px);
          left: 50%;
          color: #848e9c;
          font: {
            size: 42px;
            weight: 400;
            family: "PF Din Text Universal";
          }
          text-align: center;
          letter-spacing: 2px;
          transform: translateX(-50%);

          b {
            font: {
              family: monospace;
              weight: 100;
            }
          }
        }

        .transfers {
          position: absolute;
          width: 100%;
          height: calc(100% - 60.6%);
          top: calc(50% - 10px);
          left: 0;
          overflow: hidden;

          .item {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 70px;
            padding: 0 45px;
            margin-bottom: 58px;

            b {
              position: relative;
              top: 0px;
              left: 2px;
              color: #848e9c;
              font: {
                size: 36px;
                weight: 500;
                family: "PF Din Text Universal";
              }
              letter-spacing: 1px;
            }

            .content {
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;

              .left-side {
                display: flex;
                gap: 30px;

                .icon {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 90px;
                  height: 90px;
                  border-radius: 50%;
                  background: #f4f4f7;

                  &.received {
                    transform: rotateZ(180deg);
                  }
                }

                span {
                  position: relative;
                  top: -6px;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  justify-content: space-between;
                  color: #000000;
                  font: {
                    size: 43px;
                    weight: 400;
                    family: "PF Din Text Universal";
                  }
                  letter-spacing: 3px;

                  small {
                    color: #848e9c;
                    font: {
                      size: 38px;
                      weight: 400;
                    }
                    letter-spacing: 1px;
                  }
                }
              }

              .right-side {
                position: relative;
                top: -6px;
                right: 2px;
                color: #000000;
                font: {
                  size: 42px;
                  weight: 400;
                  family: "PF Din Text Universal";
                }
                letter-spacing: 2px;

                &.received {
                  color: #2ebd85;
                }
              }
            }

            &.today {
              margin-top: -16px;

              b {
                display: none;
              }
            }
          }
        }

        .course {
          position: absolute;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 2px;
          left: 3.6%;
          bottom: calc(3% + 21px);
          color: #000000;
          font: {
            size: 44px;
            weight: 400;
            family: "PF Din Text Universal";
          }

          b {
            color: #848e9c;
            font: {
              weight: 500;
              size: 38px;
            }
          }

          span {
            position: relative;
            margin-left: 30px;

            &.green {
              color: #2ebd85;
            }

            &.red {
              color: #e23939;
            }
          }
        }

        .other {
          position: absolute;
          top: calc(29% + 3px);
          left: 0;
          width: 1290px;
          height: 230px;
          background-image: url(../../../assets/img/generator/wallets/trustwallet/coin/other-style-1-light.png);
        }

        .chart-wrapper {
          position: absolute;
          left: calc(100% + 9px);
          width: 196px;
          height: 40px;
          top: 41px;

          canvas {
            width: 100%;
            height: auto;
          }
        }

        &.dark {
          .name {
            color: #eaebef;
          }

          .currency {
            color: #eaebef;
          }

          .transfers {
            .item {
              .content {
                .left-side {
                  .icon {
                    background: #242424;
                  }

                  span {
                    color: $color-white;
                  }
                }

                .right-side {
                  color: #eaebef;

                  &.received {
                    color: #48ff91;
                  }
                }
              }
            }
          }

          .course {
            color: #eaebef;

            span {
              &.green {
                color: #48ff91;
              }

              &.red {
                color: #e23939;
              }
            }
          }

          .other {
            background-image: url(../../../assets/img/generator/wallets/trustwallet/coin/other-style-1-dark.png);
          }
        }
      }

      &.transfer {
        .currency {
          position: absolute;
          width: 100%;
          top: calc(13% + 17px);
          left: 50%;
          color: #000000;
          font: {
            size: 72px;
            family: "PF Din Text Universal";
          }
          text-align: center;
          letter-spacing: 2px;
          transform: translateX(-50%);

          &.rece {
            color: #2ebd85;
          }
        }

        .total {
          position: absolute;
          width: 100%;
          top: calc(17% + 12px);
          left: 50%;
          color: #848e9c;
          font: {
            size: 48px;
            weight: 400;
            family: "PF Din Text Universal";
          }
          text-align: center;
          letter-spacing: 2px;
          transform: translateX(-50%);

          b {
            font: {
              family: monospace;
              weight: 100;
            }
          }
        }

        .date {
          position: absolute;
          top: calc(26% + 14px);
          right: calc(7% - 2px);
          color: #000000;
          font: {
            size: 42px;
            weight: 400;
            family: "PF Din Text Universal";
          }
          letter-spacing: 1px;
        }

        .status,
        .receiver,
        .commission {
          position: absolute;
          top: calc(30% + 12px);
          right: calc(7% - 2px);
          color: #000000;
          font: {
            size: 42px;
            weight: 400;
            family: "PF Din Text Universal";
          }
          letter-spacing: 3px;

          &.processing {
            color: #cfa136;
          }
        }

        .receiver {
          top: calc(34% + 11px);
          letter-spacing: 2px;
        }

        .commission {
          top: calc(42% + 17px);
        }

        .operation {
          position: absolute;
          top: calc(34% + 11px);
          left: calc(7% - 2px);
          color: #818f9e;
          font: {
            size: 42px;
            weight: 400;
            family: "PF Din Text Universal";
          }
          letter-spacing: 3px;
        }

        &.dark {
          .currency {
            color: #eaecef;

            &.rece {
              color: #48ff91;
            }
          }

          .date {
            color: #eaecef;
            font-weight: 300;
          }

          .status,
          .receiver,
          .commission {
            color: #eaecef;
            font-weight: 300;

            &.processing {
              color: #f0b90b;
            }
          }
        }
      }

      &.main {
        color: #202630;
        font-family: "PF Din Text Universal";

        .name-wallet {
          position: absolute;
          top: 472px;
          left: 130px;
          font: {
            size: 45px;
            weight: 500;
          }
          letter-spacing: 0.7px;

          &::after {
            content: "";
            position: absolute;
            top: 25px;
            right: -40px;
            width: 0px;
            height: 0px;
            border-left: 12px solid transparent;
            border-right: 12px solid transparent;
            border-top: 14px solid #9098a2;
          }

          &::before {
            content: "";
            position: absolute;
            top: 22px;
            right: -40px;
            width: 24px;
            height: 3px;
            background-color: #9098a2;
          }
        }

        .amount {
          position: absolute;
          top: 528px;
          left: 45px;
          font: {
            size: 98px;
            weight: 500;
          }
        }

        .currency-list {
          position: absolute;
          top: 1223px;
          left: 1px;
          width: 100%;
          text-align: left;
        }

        .currency-item {
          display: flex;
          width: 100%;
          padding-left: 53px;
          padding-right: 53px;
          gap: 34px;
          line-height: 1;

          &:not(:first-of-type) {
            margin-top: 72px;
          }
        }

        .currency-item-icon {
          width: 111px;
        }

        .currency-item-name {
          font: {
            size: 48px;
            weight: 500;
          }
          letter-spacing: 2px;
          padding-top: 6px;
        }

        .currency-item-course {
          position: relative;
          padding-top: 15px;
          top: 4px;
          font: {
            size: 37px;
            weight: 500;
          }
          color: #848e9c;
        }

        .currency-item-course-delta {
          padding-left: 12px;

          &.up {
            color: #3de580;
          }

          &.down {
            color: #e33b54;
          }
        }

        .currency-item-balance {
          margin-right: 0;
          margin-left: auto;
          text-align: right;
        }

        .currency-item-balance-amount {
          position: relative;
          top: 7px;
          left: -3px;
          font: {
            size: 49px;
            weight: 500;
          }
        }

        .currency-item-balance-usd {
          position: relative;
          top: 23px;
          left: -4px;
          color: #848e9c;
          font: {
            size: 37px;
            weight: 500;
          }
        }

        .currency-item-tag {
          position: relative;
          left: 12px;
          top: -3px;
          border-radius: 7px;
          color: #848e9c;
          font: {
            size: 38px;
            weight: 500;
          }
          padding: 1px 10px;
          background: #f4f4f7;
        }

        &.dark {
          color: #eaecef;

          .name-wallet {
            &::after {
              border-top: 14px solid #707a8a;
            }

            &::before {
              background-color: #707a8a;
            }
          }

          .currency-item-tag {
            background-color: #242427;
            color: #848e9c;
          }

          .currency-item-course-delta {
            &.up {
              color: #48ff91;
            }
            &.down {
              color: #e23939;
            }
          }
        }
      }

      &.history {
        .transfers {
          position: absolute;
          width: 100%;
          height: calc(100% - 24.6%);
          top: calc(23% - 7px);
          left: 0;
          overflow: hidden;

          .item {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 70px;
            padding: 0 45px;
            margin-bottom: 57px;

            b {
              position: relative;
              top: 0px;
              left: 2px;
              color: #848e9c;
              font: {
                size: 37px;
                weight: 400;
                family: "PF Din Text Universal";
              }
              letter-spacing: 1.3px;
            }

            .content {
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;

              .left-side {
                display: flex;
                gap: 30px;

                .icon {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 90px;
                  height: 90px;
                  border-radius: 50%;
                  background: #f4f4f7;

                  &.received {
                    transform: rotateZ(180deg);
                  }
                }

                span {
                  position: relative;
                  top: -6px;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  justify-content: space-between;
                  color: #000000;
                  font: {
                    size: 43px;
                    weight: 400;
                    family: "PF Din Text Universal";
                  }
                  letter-spacing: 3px;

                  small {
                    color: #848e9c;
                    font: {
                      size: 38px;
                      weight: 400;
                    }
                    letter-spacing: 1px;
                  }
                }
              }

              .right-side {
                position: relative;
                top: -7px;
                right: 2px;
                color: #000000;
                font: {
                  size: 42px;
                  weight: 500;
                  family: "PF Din Text Universal";
                }
                letter-spacing: 1px;

                &.received {
                  color: #2ebd85;
                }
              }
            }

            &.today {
              margin-top: -16px;

              b {
                display: none;
              }
            }
          }
        }

        &.dark {
          .transfers {
            .item {
              .content {
                .left-side {
                  .icon {
                    background: #242424;
                  }

                  span {
                    color: $color-white;
                  }
                }

                .right-side {
                  color: #eaebef;

                  &.received {
                    color: #48ff91;
                  }
                }
              }
            }
          }
        }
      }
    }

    /* 1.2.6 Exodus */
    &.exodus {
      color: #c8c8ce;
      font-family: "SF UI Text";

      &.sent,
      &.received {
        .amount,
        .fee {
          position: absolute;
          top: calc(16% + 5px);
          left: 50%;
          font: {
            size: 47px;
            weight: 400;
          }
          transform: translateX(-50%);
        }

        .notes {
          position: absolute;
          width: 81%;
          height: 110px;
          top: calc(28% + 1px);
          left: 50%;
          color: #fff;
          font: {
            size: 47px;
            weight: 400;
          }
          overflow: hidden;
          line-height: 57px;
          word-break: break-all;
          letter-spacing: -1px;
          text-align: center;
          transform: translateX(-50%);

          s {
            color: #7964e4;
          }
        }

        .date {
          position: absolute;
          top: calc(39% + 22px);
          left: 50%;
          color: #fff;
          font: {
            size: 47px;
            weight: 400;
          }
          letter-spacing: -1px;
          transform: translateX(-50%);
        }

        .txid,
        .wallet {
          position: absolute;
          width: 81%;
          top: calc(50% + 17px);
          left: 50%;
          color: #907aff;
          font: {
            size: 47px;
            weight: 400;
          }
          text-align: center;
          line-height: 57px;
          word-break: break-all;
          letter-spacing: -1px;
          transform: translateX(-50%);
        }

        .amount-now,
        .amount-old {
          position: absolute;
          top: calc(63% + 10px);
          left: 50%;
          color: #fff;
          font: {
            size: 47px;
            weight: 400;
          }
          transform: translateX(-50%);
        }

        .amount-old {
          display: flex;
          top: calc(72% + 23px);
          flex-direction: column;
          align-items: center;

          span {
            font-size: 42px;
            color: #c8c9ce;
            text-transform: capitalize;
          }

          s {
            padding-top: 13px;
          }
        }
      }

      &.sent {
        .amount {
          color: #9398a3;
        }

        .fee {
          color: #9398a3;
          top: calc(28% - 1px);
        }

        .notes {
          top: calc(39% + 24px);
        }

        .date {
          top: calc(51% + 16px);
        }

        .wallet {
          top: calc(63% + 14px);
        }

        .txid {
          top: calc(74% + 7px);
        }

        .amount-now {
          top: 87%;
        }

        .amount-old {
          top: calc(96% + 12px);
        }
      }

      &.coin {
        .name {
          position: absolute;
          top: calc(6% + 6px);
          left: 50%;
          color: #9ba7c9;
          font: {
            size: 42px;
            weight: 400;
            family: "SF UI Display";
          }
          letter-spacing: 2px;
          text-transform: uppercase;
          transform: translateX(-50%);

          .network {
            position: relative;
            font-size: 34px;
            font-weight: 500;
            color: #000;
            margin-left: 25px;
            padding: 7px 23px;
            border-radius: 30px;

            &.none {
              display: none;
            }

            &.ETH {
              color: #000;
              background: linear-gradient(90deg, white, #d6daff);
            }

            &.TRX {
              color: #fff;
              background: linear-gradient(90deg, #d01e25, #a41835);
            }

            &.ALGO {
              color: #000;
              background: linear-gradient(90deg, white, #d6daff);
            }

            &.SOL {
              color: #fff;
              background: linear-gradient(90deg, #0ef0a9, #d525fd);
            }

            &.AVAXC {
              color: #fff;
              background: linear-gradient(90deg, #e84142, #f97a7b);
            }

            &.POLYGON {
              color: #fff;
              background: linear-gradient(90deg, #8f4efe, #682ec8);
            }

            &.BSC {
              color: #fff;
              background: linear-gradient(90deg, #dab03e, #e8c353);
            }
          }
        }

        .icons {
          position: absolute;
          top: calc(5% + 12px);
          right: calc(6% + 1px);
          display: flex;
          align-items: center;
          gap: 45px;
        }

        .icon {
          position: absolute;
          top: calc(10% + 5px);
          left: 50%;
          transform: translateX(-50%);

          img {
            width: 181px;
            height: 181px;
          }

          .network {
            position: absolute;
            top: 6px;
            right: -26px;
            width: 80px;
            height: 80px;
            z-index: 1;

            img {
              background: #1a1a1a;
              border-radius: 50%;
              width: 80px;
              height: 80px;
            }
          }
        }

        .price {
          position: absolute;
          top: calc(18% + 12px);
          left: 50%;
          color: #ffffff;
          font: {
            size: 98px;
            weight: 200;
            family: "SF UI Display";
          }
          transform: translateX(-50%);

          s {
            font-size: 59px;
          }
        }

        .change-price {
          position: absolute;
          top: calc(22% + 21px);
          left: calc(53% + 8px);
          color: #9394a1;
          font: {
            size: 42px;
            weight: 400;
            family: "SF UI Display";
          }

          &.up {
            color: #4cc207;
          }
        }

        .chart {
          position: absolute;
          top: calc(32% - 5px);
          left: calc(3% - 1px);
          width: calc(93% + 3px);

          .highest-price,
          .lowest-price {
            position: absolute;
            width: calc(100% - 34px);
            top: -15px;
            left: 23px;
            color: #505269;
            font: {
              size: 29px;
              weight: 400;
              family: "SF UI Text";
            }
            letter-spacing: 1.3px;
            text-align: left;

            s {
              position: relative;
              padding-left: 45px;
              padding-right: 45px;
              background: #1c2235;
              z-index: 2;
            }

            &::after {
              content: "";
              position: absolute;
              width: 100%;
              height: 3px;
              top: 47%;
              left: 0;
              background-color: #33394a;
            }
          }

          .lowest-price {
            top: unset;
            bottom: -14px;
          }
        }

        .amount {
          position: absolute;
          display: flex;
          flex-direction: column;
          gap: 10px;
          top: calc(58% - 9px);
          left: calc(5% - 4px);
          width: calc(45% + 2px);
          font: {
            size: 61px;
            weight: 700;
          }
          text-align: center;

          s {
            color: #68718d;
            font: {
              size: 42px;
              weight: 400;
            }
          }

          &.usd {
            left: unset;
            right: calc(5% - 4px);
            color: #ffffff;
          }
        }

        .buttons {
          position: absolute;
          width: 100%;
          top: calc(65% + 22px);
          left: 50%;
          transform: translateX(-50%);
          text-align: center;
        }

        .description {
          position: absolute;
          width: 100%;
          height: 460px;
          top: calc(83% + 13px);
          left: 0;
          overflow: hidden;

          p {
            font-size: 48px;
            font-weight: 400;
            color: #8f909a;
            text-align: left;
            line-height: 75px;
            letter-spacing: -1px;
            padding: 0 60px 78px;
          }
        }

        &::after {
          content: "";
          position: absolute;
          width: calc(36% - 3px);
          height: 15px;
          bottom: 24px;
          left: 50%;
          border-radius: 10px;
          background-color: #fff;
          transform: translateX(-50%);
        }
      }

      .USDT {
        color: #53ae94;
      }

      .BTC {
        color: #ffc82d;
      }

      .ETH {
        color: #8c93af;
      }

      .LTC {
        color: #d6d6d6;
      }

      .DOGE {
        color: #a8a27f;
      }

      .TRX {
        color: #d11e25;
      }

      .BNB {
        color: #ffb700;
      }
    }

    /* 1.2.7 Sberbank */
    &.sberbank {
      &.main {
        .cardholder {
          position: absolute;
          top: calc(12% - 1px);
          left: 4%;
          color: #feffff;
          font: {
            size: 77px;
            weight: 700;
            family: "SBSansDisplay";
          }
          filter: blur(0.5px);
        }

        .amount {
          position: absolute;
          top: calc(44% + 12px);
          left: calc(21% - 7px);
          color: #000000;
          font: {
            size: 65px;
            weight: 600;
            family: "SBSansDisplay";
          }
          filter: blur(0.5px);
        }

        .last-card {
          position: absolute;
          top: calc(47% + 27px);
          left: calc(32% - 7px);
          color: #737373;
          font: {
            size: 38px;
            weight: 400;
            family: "SBSansText";
          }
          filter: blur(0.5px);
        }

        .last-bill {
          position: absolute;
          top: calc(50% + 16px);
          left: calc(56% - 3px);
          color: #737373;
          font: {
            size: 36px;
            weight: 400;
            family: "SBSansText";
          }
          filter: blur(0.5px);
        }

        &.dark {
          .cardholder {
            font-weight: 600;
          }

          .amount {
            color: #ffff;
            font-weight: 500;
          }

          .last-card,
          .last-bill {
            color: #868686;
          }
        }
      }

      &.card {
        .amount {
          position: absolute;
          top: calc(45% + 23px);
          left: calc(18% - 16px);
          color: #000000;
          font: {
            size: 49px;
            weight: 600;
            family: "SBSansDisplay";
          }
          filter: blur(0.5px);
        }

        .last-card {
          position: absolute;
          top: calc(26% + 31px);
          left: calc(20% - 7px);
          color: #fff;
          font: {
            size: 47px;
            weight: 400;
            family: "SBSansText";
          }
          filter: blur(0.5px);
        }

        .last-bill {
          position: absolute;
          top: calc(48% + 15px);
          left: calc(27% + 3px);
          color: #737373;
          font: {
            size: 39px;
            weight: 400;
            family: "SBSansText";
          }
          filter: blur(0.5px);
        }

        &.dark {
          .amount {
            color: #fefefe;
            font-weight: 500;
          }

          .last-bill {
            color: #828282;
          }
        }
      }

      &.bill {
        .amount {
          position: absolute;
          top: calc(19% + 9px);
          left: calc(5% - 13px);
          color: #ffffff;
          font: {
            size: 98px;
            weight: 700;
            family: "SBSansDisplay";
          }
          filter: blur(0.5px);
        }

        .last-card {
          position: absolute;
          top: calc(68% + 31px);
          left: calc(20% - 7px);
          color: #737373;
          font: {
            size: 39px;
            weight: 400;
            family: "SBSansText";
          }
          filter: blur(0.5px);
        }

        .last-bill {
          position: absolute;
          top: calc(8% - 3px);
          left: calc(48% + 2px);
          color: #ffffff;
          font: {
            size: 36px;
            weight: 400;
            family: "SBSansText";
          }
          filter: blur(0.5px);
        }

        &.dark {
          .last-card {
            color: #828282;
          }
        }
      }

      &.delivered {
        .amount {
          position: absolute;
          top: calc(24% + 15px);
          left: calc(50% + 4px);
          color: #ffffff;
          font: {
            size: 97px;
            weight: 700;
            family: "SBSansDisplay";
          }
          filter: blur(0.5px);
          transform: translateX(-50%);
        }

        .full-name {
          position: absolute;
          top: calc(31% + 6px);
          left: calc(50% + 4px);
          color: #ffffff;
          font: {
            size: 39px;
            weight: 500;
            family: "SBSansDisplay";
          }
          letter-spacing: 1.1px;
          filter: blur(0.5px);
          transform: translateX(-50%);

          &.v2 {
            top: calc(73% + 21px);
            left: calc(4% + 3px);
            color: #000000;
            font: {
              size: 46px;
              weight: 600;
            }
            transform: none;
          }
        }

        .last-card {
          position: absolute;
          top: calc(64% + 30px);
          left: calc(8% - 10px);
          color: #737373;
          font: {
            size: 39px;
            weight: 400;
            family: "SBSansText";
          }
          filter: blur(0.5px);
        }

        .recipient-card {
          position: absolute;
          top: calc(82% + 2px);
          left: calc(8% - 4px);
          color: #000000;
          font: {
            size: 46px;
            weight: 400;
            family: "SBSansText";
          }
          filter: blur(0.5px);
        }

        &.dark {
          .full-name {
            &.v2 {
              color: #fefefe;
              font-weight: 500;
              letter-spacing: 1.5px;
            }
          }

          .last-card {
            color: #828282;
          }

          .recipient-card {
            color: #fefefe;
          }
        }
      }

      &.completed {
        .amount {
          position: absolute;
          top: calc(33% + 12px);
          left: calc(50% + 4px);
          color: #000000;
          font: {
            size: 99px;
            weight: 700;
            family: "SBSansDisplay";
          }
          filter: blur(0.5px);
          transform: translateX(-50%);
        }

        .sender {
          position: absolute;
          top: calc(39% + 18px);
          left: calc(50% + 4px);
          color: #000000;
          font: {
            size: 45px;
            weight: 600;
            family: "SBSansDisplay";
          }
          letter-spacing: 1.1px;
          filter: blur(0.5px);
          transform: translateX(-50%);
        }

        .last-card-debit {
          position: absolute;
          top: calc(69% + 17px);
          left: calc(8% - 2px);
          color: #000000;
          font: {
            size: 45px;
            weight: 400;
            family: "SBSansText";
          }
          filter: blur(0.5px);
        }

        .last-card-enroll {
          position: absolute;
          top: calc(80% + 16px);
          left: calc(9% - 20px);
          color: #737373;
          font: {
            size: 37px;
            weight: 400;
            family: "SBSansText";
          }
          filter: blur(0.5px);
        }

        .date {
          position: absolute;
          top: calc(89% + 3px);
          left: calc(4% - 2px);
          color: #000000;
          font: {
            size: 49px;
            weight: 400;
            family: "SBSansText";
          }
          letter-spacing: -2px;
          filter: blur(0.5px);
        }

        &.dark {
          .amount {
            color: #fefefe;
          }

          .sender {
            color: #fefefe;
            font: {
              size: 46px;
              weight: 500;
            }
          }

          .last-card-debit {
            color: #ffffff;
          }

          .last-card-enroll {
            color: #828282;
          }

          .date {
            color: #fff;
          }
        }
      }
    }

    /* 1.2.8 Alfa-Bank */
    &.alfa {
      &.main {
        color: #000;

        .cardholder {
          position: absolute;
          top: calc(7% + 14px);
          left: calc(15% + 6px);
          font: {
            size: 52px;
            weight: 500;
            family: "Inter";
          }

          svg {
            position: absolute;
            top: 6px;
            right: -67px;
          }
        }

        .balance {
          position: absolute;
          top: calc(15% + 1px);
          left: 30%;
          font: {
            size: 61px;
            weight: 600;
            family: "Inter";
          }
          letter-spacing: 1px;

          span {
            font-weight: 500;
          }
        }

        .last-card {
          position: absolute;
          top: calc(18% + 13px);
          left: calc(8% - 2px);
          color: #fefefe;
          font: {
            size: 41px;
            weight: 500;
            family: "Inter";
          }
        }
      }

      &.refill,
      &.withdrawal {
        color: #000;

        .balance {
          position: absolute;
          top: calc(22% + 1px);
          left: calc(8% + 5px);
          color: #4ca16a;
          font: {
            size: 86px;
            weight: 400;
            family: "Inter";
          }
          letter-spacing: -1px;

          b {
            font-weight: 700;
          }
        }

        .date {
          position: absolute;
          top: calc(30% + 14px);
          left: calc(4% + 3px);
          color: #878689;
          font: {
            size: 42px;
            weight: 400;
            family: "Inter";
          }
          letter-spacing: -0.5px;
        }

        .last-card {
          position: absolute;
          top: calc(63% + 10px);
          left: calc(35% + 3px);
          font: {
            size: 54px;
            weight: 500;
            family: "Inter";
          }
        }

        .code {
          position: absolute;
          top: calc(71% + 15px);
          left: calc(4% + 3px);
          font: {
            size: 54px;
            weight: 500;
            family: "Inter";
          }

          text-align: left;
          line-height: 78px;
          letter-spacing: -1.5px;

          s {
            letter-spacing: 0;
          }
        }

        .header-phone {
          &.ios {
            &.light {
              .battery {
                &.charge {
                  opacity: 0.5;
                }
              }
            }
          }
        }
      }

      &.withdrawal {
        .type-icon {
          position: absolute;
          top: calc(10% + 18px);
          left: 4%;
        }

        .balance {
          left: 7%;
          color: #000000;
        }

        .date {
          top: calc(33% + 2px);
        }

        .last-card {
          top: calc(68% + 15px);
        }

        .code {
          top: calc(76% + 21px);

          &.head {
            top: calc(27% + 14px);
            line-height: 69px;
          }
        }
      }

      &.card {
        color: #000;

        .balance {
          position: absolute;
          top: calc(13% + 12px);
          left: 50%;
          font: {
            size: 98px;
            weight: 600;
            family: "Inter";
          }
          letter-spacing: 1px;
          transform: translateX(-50%);

          span {
            font-weight: 400;
          }
        }

        .last-bill {
          position: absolute;
          top: calc(18% + 18px);
          left: 60%;
          color: #89898b;
          font: {
            size: 43px;
            weight: 400;
            family: "Inter";
          }
        }

        .cards {
          position: absolute;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          top: calc(23% + 15px);
          left: calc(49% + 3px);
          gap: 29px;
          transform: translateX(-50%);

          .item {
            position: relative;

            p {
              position: absolute;
              left: 51px;
              bottom: 0;
              color: #fefefe;
              font: {
                size: 42px;
                weight: 500;
                family: "Inter";
              }
              letter-spacing: 1px;
            }
          }
        }

        .transfer-list {
          &:nth-child(2) {
            .btn-remove {
              top: 50%;
            }
          }
        }
      }
    }
  }

  /* 1.3 Other Components */
  .coin-form-list {
    margin-bottom: 10px;

    .no-data {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 10px;
      padding: 30px;
      border-radius: 10px;
      background-color: $second-element-color;
      z-index: 1;
    }

    .coin-item {
      position: relative;
      padding-bottom: 20px;
      margin-bottom: 25px;
      border-bottom: 1px dashed #383b40;

      &::after,
      &::before {
        content: "";
        position: absolute;
        width: 6px;
        height: 6px;
        left: -3px;
        bottom: -3px;
        border-radius: 50%;
        background-color: $background-grey-200;
      }

      &::before {
        left: unset;
        right: 0px;
        bottom: -3px;
      }

      &:last-child {
        border: none;
        padding-bottom: 0;
        margin-bottom: 0;

        &::after,
        &::before {
          display: none;
        }
      }
    }
  }

  .transfer-list {
    position: relative;
    padding-left: 10px;

    .item {
      position: relative;
      padding: 5px 10px;
      border-left: 1px dashed $background-grey-200;

      &::after,
      &::before {
        content: "";
        position: absolute;
        width: 6px;
        height: 6px;
        top: -6px;
        left: -3px;
        border-radius: 50%;
        background-color: $background-grey-200;
      }

      &::before {
        top: unset;
        bottom: 0;
      }

      .check-box {
        position: absolute;
        top: 0;
        right: 10px;
      }
    }

    .btn-st {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 7px;
      width: 100%;
      border: none;
      margin: 10px 0;
      color: $color-white;
      font: {
        family: $font-main;
        weight: 700;
      }
      min-height: 40px;
      border-radius: 6px;
      background: $background-color;
      transition: opacity 0.3s;

      &:hover {
        opacity: 0.5;
      }
    }

    .btn-remove {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      left: -3px;
      top: 50%;
      padding: 3px;
      min-height: 20px;
      background: $element-color;
      transform: translateY(-50%);
      transition: opacity 0.3s;

      &:hover {
        opacity: 0.5;
      }
    }

    &:last-child {
      .btn-remove {
        top: calc(50% - 25px);
      }
    }

    &.alfa {
      &:nth-child(4) {
        .btn-remove {
          top: 50%;
        }
      }
    }
  }

  .apexcharts-tooltip,
  .apexcharts-xcrosshairs,
  .apexcharts-grid-borders,
  .apexcharts-series-markers-wrap {
    display: none;
  }
}
